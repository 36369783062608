.news {
  margin-right: 30px;
  margin-top: 10px;

  a.btn-primary {
    color: #fff;
  }

  .block-btn-group-add {
    margin-bottom: 20px;
  }

  .pagination {
    margin-top: 0;
  }

  .panel-heading {
    background-color: #ffcb6c;
    background-image: -moz-linear-gradient(top, #f9ea9c 0, #ffcb6c 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f9ea9c), color-stop(100%, #ffcb6c));
    background-image: -webkit-linear-gradient(top, #f9ea9c 0, #ffcb6c 100%);
    background-image: -o-linear-gradient(top, #f9ea9c 0, #ffcb6c 100%);
    background-image: -ms-linear-gradient(top, #f9ea9c 0, #ffcb6c 100%);
    background-image: linear-gradient(to bottom, #f9ea9c 0, #ffcb6c 100%);
    filter: progid:dximagetransform.microsoft.gradient(startcolorstr='#f9ea9c', endcolorstr='#ffcb6c');
    -pie-background: linear-gradient(#f9ea9c, #ffcb6c);
    text-shadow: 0 1px 0 #f9ea9c;
  }
}

#modalNewsBanner {
  input[type="file"] {
    width: 100%;
  }

  .banner-preview {
    img {
      width: 100%;
    }
  }

  .price-list-block {
    .checkbox {
      margin-top: 0;
      margin-bottom: 5px;
    }

    .price-list-preview {
      a {
        word-wrap: break-word;
      }
    }
  }
}
